.storage {
  font-family: "VT323", monospace;
  color: var(--text-color);
  padding: 20px;
}

.storage h2, .storage h3 {
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 1rem;
  font-weight: normal;
  text-align: center;
}

.storage h3 {
  font-size: 1.5rem;
}

.storage-content {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
}

.character-count {
  font-size: 12px;
  color: var(--smaller-text);
  text-align: right;
  margin-top: 4px;
}

.weapon-form-container {
  max-width: 600px;
  margin: 0 auto 30px;
}

.weapon-form {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}


.form-group > select,
.form-group > input,
.form-group > textarea,
.prefixes .prefix-selects select,
.add-character-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "VT323", monospace;
  box-sizing: border-box;
}

.form-group > select,
.prefixes .prefix-selects select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  padding-right: 30px;
}

.prefixes .prefix-selects {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.prefixes .prefix-selects select {
  width: calc(33.33% - 7px);
}

.submit-btn, .cancel-btn,
.character-btn, .add-character-btn, .add-character-form button,
.back-btn {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px 15px;
  cursor: pointer;
  font-family: "VT323", monospace;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.submit-btn, .cancel-btn {
  padding: 10px 15px;
  font-size: 16px;
}

.submit-btn:hover, .character-btn:hover, .add-character-btn:hover,
.add-character-form button:hover, .back-btn:hover {
  background-color: var(--text-color);
  color: var(--background-color);
}

.edit-btn:hover {
  background-color: #4CAF50;
  color: white;
}

.delete-btn:hover {
  background-color: #ff4d4d;
  color: white;
}

.cancel-btn:hover {
  background-color: #f44336;
  color: white;
}

.cancel-btn {
  margin-left: 10px;
}

.weapon-list-container {
  width: 100%;
}

.weapon-list-container {
  width: 100%;
}

.weapon-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.weapon-item {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.weapon-name {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px;
  border-radius: 4px;
  text-align: center;
}

.weapon-prefixes {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.prefix {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.weapon-details {
  color: var(--text-color);
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 4px;
  border-radius: 4px;
}

.weapon-comments {
  font-style: italic;
  color: var(--smaller-text);
  background-color: rgba(255, 255, 255, 0.05);
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.weapon-item-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: auto;
}

.edit-btn {
  background-color: transparent;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-family: "VT323", monospace;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 10px;
}

.character-selection {
  text-align: center;
}

.character-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.add-character-form {
  margin-top: 20px;
}

.add-character-form input {
  margin-right: 10px;
}

.add-character-form button {
  margin-right: 5px;
}

.back-btn {
  max-width: 600px;
  margin: 0 auto 30px;
}

@media (max-width: 768px) {
  .weapon-list {
    grid-template-columns: 1fr;
  }
}