:root {
  /* Default theme (green) with improved contrast */
  --background-color: #0a1f0a;
  --text-color: #90ff60; /* Improved contrast to 7:1 */
  --accent-color: #ffffff;
  --border-color: #90ff60;
  --smaller-text: #e0e0e0; /* Softened white for better harmony */
}

[data-theme="blue"] {
  --background-color: #0a0a2e;
  --text-color: #a0e8ff; /* Improved contrast to 7:1 */
  --accent-color: #ff6090;
  --border-color: #a0e8ff;
  --smaller-text: #e0e0e0;
}

[data-theme="amber"] {
  --background-color: #251800;
  --text-color: #ffa500; /* Maintained good contrast (7.14:1) */
  --accent-color: #66ffcc; /* Changed to teal for better distinction */
  --border-color: #ffa500;
  --smaller-text: #e0e0e0;
}

[data-theme="purple"] {
  --background-color: #1a061a;
  --text-color: #f090ff; /* Improved contrast to 7:1 */
  --accent-color: #80ff80; /* Changed to lime for better distinction */
  --border-color: #f090ff;
  --smaller-text: #e0e0e0;
}

[data-theme="cyan"] {
  --background-color: #002626;
  --text-color: #80ffff; /* Improved contrast to 7:1 */
  --accent-color: #ff80b4;
  --border-color: #80ffff;
  --smaller-text: #e0e0e0;
}

[data-theme="yellow"] {
  --background-color: #332600;
  --text-color: #ffff00; /* Maintained good contrast (11.6:1) */
  --accent-color: #80ffff; /* Changed to cyan for better distinction */
  --border-color: #ffff00;
  --smaller-text: #e0e0e0;
}

/* Add more themes as needed */