.calculator {
  max-width: 1200px;
  margin: 0 auto;
  font-family: "VT323", monospace;
  color: var(--text-color);
  padding: 20px;
}

.calculator-sections {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.calculator-section {
  flex: 1;
  min-width: 300px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.05),
    rgba(0, 0, 0, 0.2)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 25px;
}

.calculator h2 {
  color: var(--text-color);
  margin-bottom: 25px;
  text-align: center;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 12px;
  font-size: 1.8em;
}

.calculator-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 25px;
  margin-bottom: 30px;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 10px;
  font-size: 1.2em;
  text-align: center;
  color: var(--text-color);
}

.calculator input[type="number"],
.calculator input[type="text"],
.calculator select {
  width: 100%;
  max-width: 300px;
  padding: 12px 15px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid var(--border-color);
  border-radius: 8px;
  color: var(--smaller-text);
  font-family: "VT323", monospace;
  font-size: 1.1em;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.2s ease;
}

/* Prevent number input spinners */
.calculator input[type="number"]::-webkit-inner-spin-button,
.calculator input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.calculator input[type="number"] {
  -moz-appearance: textfield;
}

.calculator select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%2390ff60" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 15px);
  background-position-y: 50%;
  padding-right: 45px;
}

.calculator input[type="number"]:hover,
.calculator input[type="text"]:hover,
.calculator select:hover {
  border-color: var(--accent-color);
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff
}

.calculator input[type="number"]:focus,
.calculator input[type="text"]:focus,
.calculator select:focus {
  outline: none;
  border-color: var(--accent-color);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff !important;
}

.results-group {
  margin-top: 30px;
  padding: 20px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3)
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.results-group p {
  margin-bottom: 15px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.calculated-value {
  font-weight: bold;
  font-size: 1.3em;
  display: inline-block;
  margin: 0 3px;
  text-align: center;
  color: var(--smaller-text);
}

.ingredient-value {
  max-width: 500px;
}

.score-value {
  color: var(--smaller-text);
}

.enemy-value {
  color: var(--smaller-text);
}

.recipe-count-value {
  color: var(--smaller-text);
}

@media (max-width: 600px) {
  .calculator {
    padding: 15px;
  }

  .calculator-sections {
    flex-direction: column;
    gap: 20px;
  }

  .calculator-section {
    min-width: unset;
    padding: 20px;
  }

  .calculator h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  .calculator-grid {
    gap: 20px;
  }

  .input-group label {
    font-size: 1.1em;
  }

  .calculator input[type="number"],
  .calculator input[type="text"],
  .calculator select {
    font-size: 1em;
    padding: 10px;
    max-width: 250px;
  }

  .results-group {
    padding: 15px;
    margin-top: 25px;
  }

  .results-group p {
    font-size: 1em;
    margin-bottom: 12px;
  }

  .calculated-value {
    font-size: 1.1em;
  }

  .ingredient-value {
    max-width: 300px;
  }
}