.public-trade-profile {
    font-family: "VT323", monospace;
    color: var(--text-color);
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .public-trade-profile h3 {
    font-weight: unset;
  }

  .public-trade-profile h1 {
    text-align: center;
    margin-bottom: 30px;
    font-weight: unset;
  }
  
  .listing-prefixes {
    margin-bottom: 10px;
  }
  
  .prefix {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 2px 6px;
    border-radius: 4px;
    margin-right: 5px;
    font-size: 14px;
    display: inline-block;
  }