@import url(https://fonts.googleapis.com/css?family=VT323);

.overlay {
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  z-index: -3;
}

.overlay:before {
  background: linear-gradient(#101010 50%, rgba(16, 16, 16, 0.2) 50%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.03),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.03)
    );
  background-size: 100% 4px, 6px 100%;
  content: "";
  display: block;
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.overlay:after {
  animation: flicker 0.3s infinite;
  background: rgba(16, 16, 16, 0.2);
  content: "";
  display: block;
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wrapper {
  animation: none;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

input {
  font-family: "VT323", monospace;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  font-family: "VT323", monospace;
  color: var(--text-color);
  background-color: var(--background-color);
  overflow-x: hidden;
}

h1 {
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
  color: var(--border-color);
  margin-bottom: 1px;
  margin-top: 1px;
}

h2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 2rem;
  font-weight: unset;
}

h3 {
  color:#00dd00;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  font-size: 2rem;
  font-weight: unset;
}

h4 {
  font-size: 1rem;
  line-height: 1;
  font-weight: unset;
  color: var(--border-color);
  margin-top: 1px;
}

.header-style {
  background-color: transparent;
}

.header-content {
  display: flex;
  align-items: center;
  position: relative;
  height: 100px;
}

.title-container {
  width: 100%;
  left: 0;
  text-align: center;
}

.button-container {
  display: flex;
  gap: 10px;

  justify-content: center;
  align-items: baseline;
}

.sign-in-message {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: var(--text-color);
}

.custom-button {
  border-left: unset;
  border-right: unset;
  border-bottom: unset;
  border-top: unset;
  font-size: 1rem;
  font-family: "VT323", monospace;
  color: #ffffff;
  width: auto;
  transition: color 0.2s;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.custom-file-upload {
  border-left: unset;
  border-right: unset;
  border-bottom: unset;
  border-top: unset;
  font-size: 1rem;
  font-family: "VT323", monospace;
  color: #ffffff;
  width: auto;
  text-align: left;
  transition: color 0.2s;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.custom-button:hover,
.custom-file-upload:hover {
  color: #ff0000;
}

.theme-menu {
  border-left: unset;
  border-right: unset;
  border-bottom: unset;
  border-top: unset;
  font-size: 1rem;
  font-family: "VT323", monospace;
  color: #ffffff;
  width: auto;
  text-align: left;
  transition: color 0.2s;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px;
}

.theme-menu option {
  background-color: var(--background-color);
  color: var(--text-color);
}

.theme-menu:hover {
  color: #ff0000;
}

.theme-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  text-align: center;
}

.theme-selector label {
  color: var(--text-color);
  font-family: "VT323", monospace;
  font-size: 1rem;
}

input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 50px;
  justify-content: flex-start;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  min-height: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  box-sizing: border-box;
}

.main.shifted {
  transform: translateX(250px);
}

.search-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.search-input {
  border-radius: 0.25rem;
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--border-color);
  background-color: transparent;
  font-family: "VT323", monospace;
  color: var(--text-color);
  text-align: center;
}

.search-input::placeholder {
  font-family: "VT323", monospace;
  opacity: 1;
  text-align: center;
}

.infobox {
  min-width: 50%;
  margin: 1rem 0;
}

.info-container {
  border-radius: 0.25rem;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  background-color: transparent;
}

.plans-section {
  text-align: center;
  margin: 20px 0;
}

.plans-label,
.plans-numbers {
  font-size: 18px;
}

.plan-list {
  min-width: 50%;
  margin-bottom: 2.5rem;
}

.item-container-style {
  background-color: transparent;
  border-bottom: unset;
  border-top: unset;
  border-radius: 0.5rem;
  margin-bottom: 10px;
}

.group-title-container-style {
  border-radius: 0.25rem;
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-title-style {
  border-left: unset;
  border-right: unset;
  border-bottom: unset;
  border-top: unset;
  font-size: 1.25rem;
  font-family: "VT323", monospace;
  color: #ffffff;
  width: auto;
  text-align: left;
  transition: color 0.2s;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.group-title-style:hover {
  color: #ff0000;
}

.details-container-style {
  padding-left: 20px;
}

.plan-container-style {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.plan-container-style-apparel {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}

.plan-label {
  display: flex;
  align-items: center;
}

.plan-checkbox-style {
  appearance: none;
  border: 1px solid var(--border-color);
  padding: 5px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  margin-right: 0.5rem;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 3px; /* Adjust this value as needed */
}

.plan-checkbox-style:checked {
  background-color: var(--border-color);
  border-color: var(--border-color);
}

.plan-checkbox-style:hover {
  background-color: var(--border-color);
  border-color: var(--border-color);
}

.plan-name-style {
  font-size: 1.25rem;
  color: #ffffff;
  margin-left: 5px;
}

.location-text-style {
  margin-left: 40px;
}

.check-all-label {
  margin-right: 10px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  margin-left: 10px;
}

.check-all-checkbox {
  appearance: none;
  border: 1px solid var(--border-color);
  padding: 5px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
  margin-right: 0.5rem;
}

.check-all-checkbox:hover {
  background-color: var(--border-color);
  border-color: var(--border-color);
}

.check-all-checkbox:checked {
  background-color: var(--border-color);
  border-color: var(--border-color);
}

.site-footer {
  background-color: transparent;
  text-align: center;
  padding: 20px 10px;
  position: relative;
  bottom: 0;
  margin-top: auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-nav a {
  color: var(--text-color);
}

.footer-nav a:hover,
.footer-nav a:focus {
  text-decoration: underline;
  color: #ff0000;
}

.theme-selector-footer {
  margin-top: 10px;
}

.Obtainable-plans {
  margin-bottom: 0.5rem;
  color: #00dd00;
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.unobtainable-plans-title {
  margin-bottom: 0.5rem;
  color: #ff6b6b;
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.unobtainable-plan {
  color: var(--smaller-text);
}

.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.auth-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.auth-container.expanded .auth-content {
  max-height: 500px;
  opacity: 1;
}

.auth-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.auth-container input,
.modal-content input {
  font-family: "VT323", monospace;
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #00dd00;
  color: #00dd00;
}

.auth-container input::placeholder {
  color: #00dd00;
  opacity: 0.7;
}

.auth-container .custom-button {
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #00dd00;
  color: #00dd00;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-container .custom-button:hover {
  background-color: rgba(0, 221, 0, 0.2);
}

.auth-container .google-button {
  background-color: transparent;
  border: 1px solid #4285f4;
  color: #4285f4;
}

.auth-container .google-button:hover {
  background-color: rgba(66, 133, 244, 0.2);
}

.toggle-auth {
  width: 100%;
  max-width: 300px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: var(--border-color);
  cursor: pointer;
}

.modal-content h2 {
  color: var(--text-color);
  margin-bottom: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content input {
  border-radius: 0.25rem;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.modal-content input::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

.modal-content .custom-button {
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-content .custom-button:hover {
  background-color: rgba(0, 221, 0, 0.2);
}

.modal-content .google-button {
  background-color: transparent;
  border: 1px solid #4285f4;
  color: #4285f4;
}

.modal-content .google-button:hover {
  background-color: rgba(66, 133, 244, 0.2);
}

.content-wrapper {
  transition: filter 0.3s ease-in-out;
}

.content-wrapper.blurred {
  filter: blur(5px);
}

.loading-indicator {
  margin-left: 10px;
  color: var(--text-color);
  font-weight: bold;
}

.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background-color 0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.upload-message {
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.upload-message progress {
  width: 100%;
  margin-top: 10px;
}

.search-container {

}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.hide-completed-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}

.hide-completed-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hide-completed-checkbox {
  margin-right: 8px;
}

.auth-buttons {
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
}

.delete-btn {
  background-color: transparent;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-family: "VT323", monospace;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 10px;
}

.delete-btn:hover {
  background-color: #ff4d4d;
  color: white;
}

.sidebar nav ul li a {
  display: block;
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: "VT323", monospace;
  width: 100%;
  text-align: left;
  transition: color 0.2s;
  text-decoration: none;
  padding: 10px 0;
}

.sidebar nav ul li a:hover {
  color: #ff0000;
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid var(--border-color);
}

.sidebar-auth-buttons {
  display: flex;
  justify-content: center;
}

.sidebar-auth-buttons .custom-button {
  width: 100%;
  text-align: center;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: var(--background-color);
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.sidebar.open {
  left: 0;
}

.sidebar nav {
  flex: 1;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  margin-top: unset;
  margin-bottom: unset;
}

.sidebar-footer {
  padding: 20px;
  border-top: 1px solid var(--border-color);
  text-align: center;
}

.sidebar-footer a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.2s;
}

.sidebar-footer a:hover {
  color: #ff0000;
}

.theme-selector-sidebar {
  margin-top: 10px;
}

.sidebar nav ul li {
  padding: 10px 20px;
}

.sidebar nav ul li button,
.sidebar nav ul li a {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: "VT323", monospace;
  width: 100%;
  text-align: left;
  transition: color 0.2s;
}

.sidebar nav ul li button:hover,
.sidebar nav ul li a:hover {
  color: #ff0000;
}

.sidebar-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
}

.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;
  transition: left 0.3s ease-in-out;
}

.sidebar-toggle.open {
  left: 270px;
}


.apparel-symbol {
  font-size: 0.8em;
  margin-left: 4px;
}

.plan-name-style {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.filter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.rarity-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.rarity-filter-label {
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;
  font-size: 0.9em;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.rarity-filter-checkbox {
  margin-right: 5px;
}
/* Add these styles to your existing admin panel styles in App.css */

.admin-panel {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.csv-upload {
  margin-bottom: 20px;
}

.csv-upload button {
  padding: 10px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s;
}

.csv-upload button:hover {
  background-color: var(--border-color);
}

.csv-changes-preview {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.05);
}

.csv-changes-preview h3 {
  margin-bottom: 15px;
}

.csv-changes-preview table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.csv-changes-preview th,
.csv-changes-preview td {
  border: 1px solid var(--border-color);
  padding: 10px;
  text-align: left;
}

.csv-changes-preview th {
  background-color: var(--background-color);
  font-weight: bold;
}

.csv-changes-preview tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.csv-changes-preview .field-comparison {
  display: flex;
  flex-direction: column;
}

.csv-changes-preview .current-value {
  color: var(--text-color);
}

.csv-changes-preview .new-value {
  color: #4CAF50;
  font-weight: bold;
}

.csv-changes-preview button {
  padding: 10px 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.3s;
}

.csv-changes-preview button:hover {
  background-color: var(--border-color);
}

@media (max-width: 768px) {
  h2 {
    text-align: center;
  }

  h3 {
    text-align: center;
  }

  h4 {
    font-size: 0.8rem;
  }

  .sidebar {
    width: 100%;
    left: -100%;
  }

  .item-container-style {
    width: 100%;
    margin-bottom: 15px;
  }

  .group-title-container-style {
    flex-direction: row;
    align-items: flex;
    padding: 10px;
    width: 95%;
    margin: 0 auto;
  }

  .group-title-style {
    font-size: 1.1rem;
    width: 100%;
    padding: 5px 0;
  }

  .details-container-style {
    padding-left: 10px;
  }

  .plan-container-style,
  .plan-container-style-apparel {
    padding: 10px 5px;
  }

  .check-all-checkbox {
    transform: scale(1);
    width: 18px;
    height: 18px;
  }

  .plan-checkbox-style {
    width: 16px;
    height: 16px;
    margin-top: 2px; /* Adjust this value for smaller screens */
  }

  .check-all-label {
    font-size: 1rem;
    margin-left: 5px;
    display: flex;
    align-items: center;
  }

  .plan-name-style {
    font-size: 1rem;
    margin-left: 5px;
  }

  .location-text-style {
    margin-left: 40px;
    font-size: 15px;
    display: block;
    margin-top: 5px;
  }

  .custom-button,
  .custom-file-upload {
    font-size: 0.9rem;
    padding: 8px 12px;
  }

  .search-input {
    font-size: 14px;
    padding: 8px;
  }

  .hide-completed-container {
    margin-bottom: 15px;
  }

  .hide-completed-label {
    font-size: 0.9rem;
  }

  .delete-btn {
    font-size: 12px;
    padding: 4px 8px;
  }

  .custom-button,
  .custom-file-upload,
  .group-title-style,
  .delete-btn {
    min-width: 44px;
  }

  .plan-checkbox-style,
  .check-all-checkbox {
    position: relative;
  }

  .plan-checkbox-style::after,
  .check-all-checkbox::after {
    content: '';
    position: absolute;
    top: -13px;
    left: -13px;
    right: -13px;
    bottom: -13px;
  }

  .plans-section {
    text-align: center;
  }

  .plans-label,
  .plans-numbers {
    font-size: 1em;
    display: inline;
  }

  .plans-section span:last-child {
    font-size: 1em;
  }

  .plans-section + .plans-section {
    margin-top: 10px;
  }

  .rarity-filters {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .rarity-filter-label {
    margin-right: 0;
    margin-bottom: 0;
  }
}


@media (max-width: 480px) {
  .group-title-container-style {
    flex-direction: row;
    align-items: flex;
    width: 90%;
  }

  .group-title-style {
    font-size: 1rem;
  }

  .plan-checkbox-style,
  .check-all-checkbox {
    width: 16px;
    height: 16px;
  }

  .plan-name-style {
    font-size: 0.9rem;
  }

  .location-text-style {
    margin-left: 40px;
    font-size: 15px;
  }

  .custom-button,
  .custom-file-upload {
    font-size: 0.8rem;
    padding: 6px 10px;
  }

  .search-input {
    font-size: 12px;
    padding: 6px;
  }

  .hide-completed-label {
    font-size: 0.8rem;
  }

  .delete-btn {
    font-size: 11px;
    padding: 3px 6px;
  }
}

@media (max-width: 320px) {
  .group-title-container-style {
    display: flex;
    width: 85%;
    padding: 8px;
    flex-direction: row;
  }

  .group-title-style {
    font-size: 0.9rem;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .infobox,
  .plan-list {
    min-width: 90%;
  }

  .main {
    min-width: 90%;
    padding: unset;
  }

  .sidebar-toggle.open {
    left: calc(100% - 50px);
  }

  .main.shifted {
    margin-left: 0;
  }
}