.feedbackButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: transform 0.2s ease;
  font-family: "VT323", monospace;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .feedbackButton {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.feedbackButton:hover {
  transform: translateY(-2px);
  color: #ff0000;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modalContent {
  background-color: var(--background-color);
  padding: 24px;
  border-radius: 4px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid var(--border-color);
  font-family: "VT323", monospace;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-color);
  font-family: "VT323", monospace;
}

.closeButton:hover {
  color: #ff0000;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-weight: bold;
  color: var(--text-color);
  font-size: 1.2rem;
}

.select,
.textarea {
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "VT323", monospace;
  font-size: 1.2rem;
}

.textarea {
  min-height: 120px;
  resize: vertical;
}

.submitButton {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 12px 24px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  font-family: "VT323", monospace;
  font-size: 1.2rem;
  transition: color 0.2s ease;
}

.submitButton:hover {
  color: #ff0000;
}

.submitButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.successMessage {
  color: #4caf50;
  text-align: center;
  padding: 16px;
  margin-top: 16px;
  font-size: 1.2rem;
}

.errorMessage {
  color: #f44336;
  text-align: center;
  padding: 16px;
  margin-top: 16px;
  font-size: 1.2rem;
}

@media (max-width: 600px) {
  .modalContent {
    width: 95%;
    padding: 16px;
  }
  
  .feedbackButton {
    bottom: 16px;
    right: 16px;
    padding: 10px 20px;
    font-size: 1rem;
  }
}