.auth-buttons {
  width: 100%;
}

.signout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
}

.welcome-message {
  margin: 0 0 10px 0;
  font-size: 1.1em;
  color: var(--text-color, #333);
  text-align: center;
}
