.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.auth-form {
  background: var(--background-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.auth-button,
.google-button {
  width: 100%;
  text-align: center;
  font-family: "VT323", monospace;
  transition: color 0.2s;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 16px;
}

.auth-message.error {
  color: var(--error-color);
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--error-background);
  text-align: center;
}

.auth-divider {
  display: flex;
  align-items: center;
  text-align: center;
}

.auth-divider::before,
.auth-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--border-color);
}

.auth-divider span {
  padding: 0 1rem;
  color: var(--text-secondary);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  color: var(--text-primary);
}

.form-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background: var(--input-background);
  color: var(--text-primary);
}

.form-group input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--primary-color-alpha);
}

.forgot-password-link {
  background: none;
  border: none;
  color: white;
  padding: 0.25rem 0;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  display: block;
  width: auto;
  text-align: left;
  margin-top: 0.25rem;
  font-family: inherit;
  transition: all 0.3s ease;
}

.forgot-password-link:hover {
  color: #ff0000;
}

.auth-message.success {
  color: var(--success-color, #28a745);
  border-color: var(--success-border, #c3e6cb);
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  text-align: center;
}

.password-requirements {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.password-requirements ul {
  list-style: none;
  padding-left: 0;
  margin: 0.5rem 0;
}

.password-requirements li {
  margin: 0.25rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.password-requirements li::before {
  content: '×';
  position: absolute;
  left: 0;
  color: var(--error-color);
}

.password-requirements li.valid::before {
  content: '✓';
  color: var(--success-color, #28a745);
}

.validation-message {
  color: var(--error-color);
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.auth-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.auth-button.secondary {
  background-color: var(--background-tertiary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.auth-button.secondary:hover {
  background-color: var(--background-secondary);
}